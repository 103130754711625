import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { CompanyDatasetDTO, FundBoardAndExecDTO, FundDatasetDTO, FundDimensionsInfoDTO, FundOverviewDTO, FundsLookupRES, PortfolioHighlightDimensionInfoDTO } from './resTypes';
import { createDebouncedAsyncThunk } from 'src/shared/api/createDebouncedAsyncThunk';
import { FundsLookupREQ_BODY } from './reqTypes';
import { actionsNotifications } from 'src/app/_BLL/notifications/slice';
import { SearchResponseCompany } from 'src/pages/MainPage/api/mainSearch/types';

const NAME = UrlAPI.newfunds;

export const fundsLookup = createDebouncedAsyncThunk(
	`${NAME}/lookup`,
	async (payload: FundsLookupREQ_BODY, thunkAPI) => {
		const { signal, dispatch } = thunkAPI;

		if (payload.searchPrefix !== '') {
			const res = await apiRequest.postRequest<FundsLookupRES>({
				url: `${UrlAPI.newfunds}/lookup`,
				payload,
				thunkAPI,
				signal,
			});

			if (res.funds.length === 0) {
				dispatch(
					actionsNotifications.addNotification({
						type: 'info',
						message: 'Nothing found',
					}),
				);
			}

			return res;
		} else {
			return {
				funds: [],
				// pagination: {
				// 	pageSize: 10,
				// 	pageIndex: 0,
				// 	pageCount: 0,
				// 	count: 0,
				// },
			};
		}
	},
	500,
);

export const getOverview = createAppAsyncThunk(`${NAME}/getOverview`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FundOverviewDTO>({
		url: `${NAME}/overview`,
		params,
		thunkAPI,
	});
});

export const getHighlight = createAppAsyncThunk(`${NAME}/getHighlight`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<PortfolioHighlightDimensionInfoDTO[]>({
		url: `${NAME}/highlight`,
		params,
		thunkAPI,
	});
});

export const getPerformance = createAppAsyncThunk(`${NAME}/getPerformance`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FundDimensionsInfoDTO[]>({
		url: `${NAME}/performance`,
		params,
		thunkAPI,
	});
});

export const getBoardAndExec = createAppAsyncThunk(`${NAME}/getBoardAndExec`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FundBoardAndExecDTO[]>({
		url: `${NAME}/boardAndExec`,
		params,
		thunkAPI,
	});
});

export const getDataset = createAppAsyncThunk(`${NAME}/getDataset`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FundDatasetDTO[]>({
		url: `${NAME}/dataset`,
		params,
		thunkAPI,
	});
});

export const getFulldataset = createAppAsyncThunk(`${NAME}/getFulldataset`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<CompanyDatasetDTO[]>({
		url: `${NAME}/fulldataset`,
		params,
		thunkAPI,
	});
});

interface State {
	funds: {
		data: SearchResponseCompany[];
		status: RequestStatus;
	};
	overview: {
		data: FundOverviewDTO | null;
		status: RequestStatus;
	};
	highlight: {
		data: PortfolioHighlightDimensionInfoDTO[] | null;
		status: RequestStatus;
	};
	performance: {
		data: FundDimensionsInfoDTO[] | null;
		status: RequestStatus;
	};
	boardAndExec: {
		data: FundBoardAndExecDTO[] | null;
		status: RequestStatus;
	};
	dataset: {
		data: FundDatasetDTO[] | null;
		status: RequestStatus;
	};
	fulldataset: {
		data: CompanyDatasetDTO[] | null;
		status: RequestStatus;
	};
	selected: {
		menuId: number; // MenuId is dimensionID, at the begin is 0 (no dimensionID is 0, be carefull here)
		chart: {
			title: string | null;
			quartile: string | null;
		};
	};
}

export const initialState: State = {
	funds: {
		data: [],
		status: RequestStatus.still,
	},
	overview: {
		data: null,
		status: RequestStatus.still,
	},
	highlight: {
		data: null,
		status: RequestStatus.still,
	},
	performance: {
		data: null,
		status: RequestStatus.still,
	},
	boardAndExec: {
		data: null,
		status: RequestStatus.still,
	},
	dataset: {
		data: null,
		status: RequestStatus.still,
	},
	fulldataset: {
		data: null,
		status: RequestStatus.still,
	},

	selected: {
		menuId: 0,
		chart: {
			title: null,
			quartile: null,
		},
	},
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		clearFunds: (state) => {
			state.funds.data = initialState.funds.data;
			state.funds.status = initialState.funds.status;
		},
		setMenuId: (state, action: { payload: number }) => {
			state.selected.menuId = action.payload;
		},
		setChart: (state, action: { payload: { title: string | null; quartile: string | null } }) => {
			state.selected.chart = { ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getOverview.pending, (state) => {
			state.overview.status = RequestStatus.loading;
		});
		builder.addCase(getOverview.fulfilled, (state, action) => {
			state.overview.data = action.payload;
			state.overview.status = RequestStatus.still;
		});
		builder.addCase(getOverview.rejected, (state) => {
			state.overview.status = RequestStatus.failed;
		});

		builder.addCase(getHighlight.pending, (state) => {
			state.highlight.status = RequestStatus.loading;
		});
		builder.addCase(getHighlight.fulfilled, (state, action) => {
			state.highlight.data = action.payload;
			state.highlight.status = RequestStatus.still;
		});
		builder.addCase(getHighlight.rejected, (state) => {
			state.highlight.status = RequestStatus.failed;
		});

		builder.addCase(getPerformance.pending, (state) => {
			state.performance.status = RequestStatus.loading;
		});
		builder.addCase(getPerformance.fulfilled, (state, action) => {
			state.performance.data = action.payload;
			state.performance.status = RequestStatus.still;
		});
		builder.addCase(getPerformance.rejected, (state) => {
			state.performance.status = RequestStatus.failed;
		});

		builder.addCase(getBoardAndExec.pending, (state) => {
			state.boardAndExec.status = RequestStatus.loading;
		});
		builder.addCase(getBoardAndExec.fulfilled, (state, action) => {
			state.boardAndExec.data = action.payload;
			state.boardAndExec.status = RequestStatus.still;
		});
		builder.addCase(getBoardAndExec.rejected, (state) => {
			state.boardAndExec.status = RequestStatus.failed;
		});

		builder.addCase(getDataset.pending, (state) => {
			state.dataset.status = RequestStatus.loading;
		});
		builder.addCase(getDataset.fulfilled, (state, action) => {
			state.dataset.data = action.payload;
			state.dataset.status = RequestStatus.still;
		});
		builder.addCase(getDataset.rejected, (state) => {
			state.dataset.status = RequestStatus.failed;
		});

		builder.addCase(getFulldataset.pending, (state) => {
			state.fulldataset.status = RequestStatus.loading;
		});
		builder.addCase(getFulldataset.fulfilled, (state, action) => {
			state.fulldataset.data = action.payload;
			state.fulldataset.status = RequestStatus.still;
		});
		builder.addCase(getFulldataset.rejected, (state) => {
			state.fulldataset.status = RequestStatus.failed;
		});

		builder.addCase(fundsLookup.pending, (state, action) => {
			const searchPrefix = action.meta.arg.searchPrefix;
			state.funds.status = searchPrefix === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(fundsLookup.fulfilled, (state, action) => {
			state.funds.data = [...action.payload.funds];
			// state.data.pagination = action.payload.pagination;
			state.funds.status = RequestStatus.still;
		});
		builder.addCase(fundsLookup.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.funds.status = RequestStatus.failed;
			}
		});
	},
});

export const actionsFundsNew = {
	...slice.actions,
	fundsLookup,
	getOverview,
	getHighlight,
	getPerformance,
	getBoardAndExec,
	getDataset,
	getFulldataset,
};
